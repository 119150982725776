/* CustomToastAlert.module.css */
.snackbar {
    visibility: hidden;
    min-width: 250px;
    margin-left: -125px;
    text-align: center;
    border-radius: 8px;
    padding: 16px;
    position: fixed;
    z-index: 1;
    left: 50%;
    bottom: 30px;
    font-size: 17px;
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 0.5s, transform 0.5s;
}

.snackbar.show {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
    animation: slideIn 0.5s ease-out, slideOut 0.5s ease-in 2.5s;
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateY(100px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideOut {
    from {
        opacity: 1;
        transform: translateY(0);
    }

    to {
        opacity: 0;
        transform: translateY(100px);
    }
}

/* Type-specific styles */
.info {
    background-color: #2196F3;
    /* Blue */
    color: #fff;
}

.success {
    background-color: #4CAF50;
    /* Green */
    color: #fff;
}

.warning {
    background-color: #FF9800;
    /* Orange */
    color: #fff;
}

.error {
    background-color: #F44336;
    /* Red */
    color: #fff;
}