.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8));

  background: -webkit-radial-gradient(
    rgba(20, 20, 20, 0.8),
    rgba(0, 0, 0, 0.8)
  );
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 150ms infinite linear;
  -moz-animation: spinner 150ms infinite linear;
  -ms-animation: spinner 150ms infinite linear;
  -o-animation: spinner 150ms infinite linear;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.hand-over {
  cursor: pointer;
}

#overlay {
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 12;
  /* cursor: pointer; */
  align-items: center;
  justify-content: center;
}

.canvasjs-chart-credit {
  display: none;
}

.canvasjs-chart-canvas {
  font-family: "ubuntu-regular", sans-serif;
}

@media only screen and (max-width: 768px) {
  .content-wrapper,
  .card .card-body {
    padding: 1rem;
  }

  .page-title {
    font-size: 13px;
    margin-bottom: 0;
  }

  .page-header .breadcrumb {
    margin-bottom: 0;
    padding-right: 0;
    font-size: 10px !important;
  }

  .breadcrumb .breadcrumb-item {
    font-size: 13px;
  }

  .card .card-title,
  .card .card-description {
    margin-bottom: 10px;
  }

  .col-form-label {
    padding-top: 0;
    padding-bottom: 0;
  }

  .form-check {
    margin-top: 7px;
  }

  .ql-container {
    height: calc(100% - 66px) !important;
  }

  .ql-editor {
    padding: 10px !important;
  }

  .btn {
    padding: 0.875rem 1.5rem;
  }

  .page-header {
    margin: 0 0 10px 0;
  }

  .form-group {
    margin-bottom: 10px;
  }
}

.avatar-upload {
  position: relative;
  max-width: 205px;
  margin: 50px auto;
  display: none;

  .avatar-edit {
    position: absolute;
    right: 59px;
    z-index: 1;
    top: 10px;
  }

  .avatar-preview {
    width: 150px;
    height: 150px;
    position: relative;
    border-radius: 100%;
    border: 6px solid #f8f8f8;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);

    > div {
      width: 100%;
      height: 100%;
      border-radius: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}

/* input[type="file"] {
	display: none;

	+label {
		display: inline-block;
		width: 34px;
		height: 34px;
		margin-bottom: 0;
		border-radius: 100%;
		background: #FFFFFF;
		border: 1px solid transparent;
		box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
		cursor: pointer;
		font-weight: normal;
		transition: all .2s ease-in-out;

		&:hover {
			background: #f1f1f1;
			border-color: #d6d6d6;
		}

		&:after {
			content: "\f040";
			font-family: 'FontAwesome';
			color: #757575;
			position: absolute;
			top: 10px;
			left: 0;
			right: 0;
			text-align: center;
			margin: auto;
		}
	}
} */
.prf-wrap {
  max-width: 150px;
  margin: 50px auto;
}

.prf-box {
  width: 150px;
  height: 150px;
  position: relative;
  overflow: hidden;
}

.prf-in {
  cursor: pointer;
  position: relative;
  /* background: red; */
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 6px solid #f8f8f8;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 0;
  overflow: hidden;
}

.prf-in img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.prf-edit {
  background: #c0c0c0b3;
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 35%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.prf-edit input {
  display: none;
}

.prf-edit label {
  font-size: 30px;
}

.ft-image {
  margin-left: 10px;
  width: 66px;
}

.navbar .navbar-brand-wrapper .navbar-brand img {
  object-fit: contain;
  height: 68px;
}

.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item.dropdown
  .dropdown-menu.navbar-dropdown
  .dropdown-item {
  color: inherit !important;
}

.auth .brand-logo img {
  width: 77px;
}

.navbar .navbar-brand-wrapper .navbar-brand.brand-logo-mini img {
  width: auto;
  max-width: 100%;
  height: 48px;
  margin: auto;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.form-group.required .control-label:after {
  content: "*";
  color: red;
}

.react-datepicker-ignore-onclickoutside {
  width: 100%;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.react-datepicker__triangle {
  left: -50% !important;
}

.fEnJOm,
.iLlKWI {
  font-size: 14px;
  color: white;
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
  border: none !important;
}

.iCCOt:hover span,
.iCCOt:focus span,
.iCCOt:hover span.__rdt_custom_sort_icon__ *,
.iCCOt:focus span.__rdt_custom_sort_icon__ *,
.bEsGeb:hover span,
.bEsGeb:focus span,
.bEsGeb:hover span.__rdt_custom_sort_icon__ *,
.bEsGeb:focus span.__rdt_custom_sort_icon__ * {
  opacity: 1 !important;
}

.iCCOt:hover,
.iCCOt:focus,
.bEsGeb:hover,
.bEsGeb:focus {
  opacity: 1 !important;
}

.celVQH .rdt_TableRow:nth-child(even) {
  background-color: #f1f1f1 !important;
  border: none;
}

.celVQH .rdt_TableRow:nth-child(odd) {
  border: none;
}

.css-13cymwt-control {
  min-height: 46.06px !important;
  border-color: #ebedf2 !important;
  font-family: "ubuntu-regular", sans-serif !important;
  font-size: 0.8125rem !important;
  box-shadow: none !important;
  border-width: 1px !important;
  border-radius: 2px !important;
}

.form-control:focus,
.css-t3ipsp-control:hover,
.select__control--is-focused {
  outline: #2684ff !important;
  border-color: #2684ff !important;
  box-shadow: none !important;
  border-width: 1px !important;
  border-radius: 2px !important;
}

.css-t3ipsp-control:hover,
.select__control--is-focused {
  min-height: 46.06px !important;
  box-shadow: none !important;
  border-width: 1px !important;
  border-radius: 2px !important;
  font-size: 0.8125rem !important;
}

.col-form-label {
  padding-top: calc(0.94rem + -2px);
  padding-bottom: calc(0.94rem + -2px);
  line-height: 1.2;
  margin-bottom: 0;
}

.iGDrkx {
  padding-left: 0 !important;
}

.rdt_TableHeader {
  padding-left: 0 !important;
}

.required-field::after {
  content: " *";
  color: red;
}

.css-b62m3t-container {
  border: 1px solid #b0c2ee;
}

/* .quill {
	border: 1px solid #b0c2ee;
} */

.ql-editor {
  min-height: 100px !important;
  max-height: 300px;
  overflow: hidden;
  overflow-y: scroll;
  overflow-x: scroll;
}

.ql-container {
  border: 1px solid #b0c2ee !important;
}

.ql-toolbar {
  border: 1px solid #b0c2ee !important;
  border-bottom: none !important;
}

.icon-container {
  width: 20px;
  height: 20px;
}

.icon-container img {
  width: 100%;
  height: 100%;
}
